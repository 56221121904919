<template>
  <section class="py-5 px-10">
    <p class="text-h5 secondary--text">
      Reportería <v-icon color="secondary">mdi-printer</v-icon>
    </p>
    <v-row>
      <v-col cols="12">
        <v-row class="d-flex align-baseline">
          <v-col cols="12" sm="4">
            <v-select 
              label="Información a mostrar"
              v-model="filtros.contrato" 
              :items="contratos"
              item-value="id"
              item-text="nombre"
              outlined
              @change="getTipoContratos(), getEstados()"
            ></v-select>
          </v-col>
          <v-col cols="12" sm="4">
            <v-select 
              label="Tipo de contrato" 
              v-model="filtros.id_tipo"
              :items="tipoContratos"
              item-value="id"
              item-text="nombre"
              outlined
            ></v-select>
          </v-col>
          <v-col cols="12" sm="4">
            <v-select 
              label="Estado" 
              v-model="filtros.id_estado"
              :items="estados"
              item-value="id"
              item-text="nombre"
              outlined
            ></v-select>
          </v-col>
          <v-col cols="12" sm="3">
            <v-menu
              ref="menuDesde"
              v-model="menuDesde"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="formatFechaDesde"
                  label="Fecha inicio"
                  placeholder="MM/YYYY"
                  prepend-icon="mdi-calendar"
                  readonly
                  outlined
                  v-bind="attrs"
                  v-on="on"
                  clearable
                  @click:clear="filtros.fecha_inicio = null"
                ></v-text-field>
              </template>
              <v-date-picker 
                v-model="filtros.fecha_inicio" 
                type="month" 
                :max="filtros.fecha_fin"
                no-title scrollable
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" sm="3">
            <v-menu
              ref="menuHasta"
              v-model="menuHasta"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="formatFechaHasta"
                  label="Fecha fin"
                  placeholder="MM/YYYY"
                  prepend-icon="mdi-calendar"
                  readonly
                  outlined
                  clearable
                  v-bind="attrs"
                  v-on="on"
                  @click:clear="filtros.fecha_fin = null"
                ></v-text-field>
              </template>
              <v-date-picker 
                v-model="filtros.fecha_fin" 
                type="month"
                :min="filtros.fecha_inicio"
                no-title scrollable
              ></v-date-picker>
            </v-menu>
          </v-col>

          <v-col cols="12" class="d-inline" sm="5">
            <v-btn outlined class="mr-2 mt-2" color="#404a7a" @click="clean">Limpiar</v-btn>
            <v-btn dark class="mt-2" color="#404a7a" @click="getReportes">Buscar</v-btn>
          </v-col>
        </v-row>
        <v-row class="justify-end" v-if="mostrarTabla">
          <v-col cols="12">
            <v-row class="d-fex align-baseline">
              <v-col class="ma-0 pa-0" cols="12" sm="12" md="4">
                <StatsComponent
                  class="d-flex justify-sm-center justify-md-start"
                  :info="itemsFondos"
                />
              </v-col>
              <v-col cols="12" sm="12" md="8">
                <StatsComponent
                  class="d-flex justify-sm-center justify-lg-end"
                  :info="itemsConteo"
                />
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12">
            <DataTable
              :headers="headers"
              :items="datosTabla"
              :show_actions="true"
              :actions="actions"
              @show="show"
              @paginar="paginate"
              v-models:pagina="filtros.page"
              v-models:select="filtros.per_page"
              :total_registros="filtros.total_rows"
            >
            
              <template #[`header.monto`]>
                {{ filtros.contrato === 0 ? 'Monto presupuestado' : 'Monto Adjudicado' }}
              </template>


              <template v-slot:[`item.avance`]="{ item }">
                {{ item.avance }}%
              </template>

              <template v-slot:[`item.nombre_etapa`]="{ item }">
                <v-rating
                  v-model="item.completas"
                  hover
                  half-increments
                  empty-icon="mdi-square-rounded-outline"
                  full-icon="mdi-square-rounded"
                  dense
                  :length="item.todas === 0 ? 1 : item.todas"
                  readonly
                >
                  <template v-slot:item="{ value, index }">
                    <v-icon
                      v-if="value >= index + 1"
                      style="width: 10px; margin: 8px"
                      :color="item.avance == 100 ? 'green' : getColor(value)"
                    >
                      mdi-square-rounded
                    </v-icon>
                    <v-icon
                      v-else
                      style="width: 10px; margin: 8px"
                      :color="getColor(value)"
                    >
                      mdi-square-rounded-outline
                    </v-icon>
                  </template>
                </v-rating>
                <p class="text-center">{{ item.avance === 0 ? 'Configurando proceso' : item.nombre_etapa }}</p>
              </template>
            </DataTable>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </section>
</template>

<script>
import StatsComponent from "../AprobacionUFI/components/StatsComponent.vue";
import DataTable from "@/components/DataTableComponent.vue";

export default {
  name: "reporteria",
  components: { DataTable, StatsComponent },

  data: () => ({
    menuDesde: false,
    menuHasta: false,
    conteo: "65/25",
    value: false,
    rating: 7,
    text: "",
    mostrarTabla: false,
    contratos: [
      { id: 0, nombre: 'Proceso de compra' },
      { id: 1, nombre: 'Contratos' }
    ],
    datosTabla: [],
    tipoContratos: [],
    estados: [],
    filtros: {
      contrato: null,
      id_tipo: null,
      id_estado: null,
      fecha_inicio: null,
      fecha_fin: null,
      page: 1,
      per_page: 10,
      total_rows: null,
    },
    formatFechaDesde: null,
    formatFechaHasta: null,
    itemsFondos: [],
    itemsConteo: [],
    procesos:[],
    headers: [
      {
        text: "Correlativo",
        align: "start",
        sortable: true,
        value: "correlativo",
      },
      {
        text: "Nombre del proceso",
        align: "start",
        sortable: true,
        value: "nombre",
      },
      {
        text: "Tipo de contrato",
        align: "start",
        sortable: true,
        value: "nombre_tipo",
      },
      {
        text: "Estado",
        align: "center",
        sortable: true,
        value: "nombre_etapa",
        width: "20%",
      },
      {
        text: "Fecha de inicio",
        align: "start",
        sortable: true,
        value: "fecha_inicio",
      },
      {
        text: "Fecha estimada de fin",
        align: "start",
        sortable: true,
        value: "fecha_fin",
      },
      {
        text: "Monto",
        align: "start",
        sortable: true,
        value: "monto",
      },
      {
        text: "Avance",
        align: "start",
        sortable: true,
        value: "avance",
      },
      {
        text: "Acciones",
        align: "center",
        sortable: true,
        value: "actions",
      },
    ],
    actions: [
      {
        tooltip: {
          text: "Ver",
          color: "blueGrayMinsal",
        },
        icon: {
          color: "secondary",
          name: "mdi-eye",
        },
        eventName: "show",
      },
    ],
  }),

  watch: {
    'filtros.fecha_inicio' () {
      if(this.filtros.fecha_inicio) {
        this.formatFechaDesde = this.moment(this.filtros.fecha_inicio).format('MM/YYYY')
      } 
    },

    'filtros.fecha_fin' () {
      if(this.filtros.fecha_fin) {
        this.formatFechaHasta = this.moment(this.filtros.fecha_fin).format('MM/YYYY')
      } 
    }
  },

  methods: {
    show(item) {
      this.value = true;
    },
    getColor(estado) {
      if (estado >= 4) return "green";
      else if (estado <= 3) return "orange";
    },

    formatoDivisa (cantidad) {
      return Intl.NumberFormat('en-US', { 
        currency: 'USD', 
        style: 'currency', 
        minimumFractionDigits: 2 
      }).format(cantidad)
    },

    setDatosProcesoCompra (params) {
      const {
        compra_linea,
        contratacion_directa,
        convenio_marco,
        libre_gestion,
        licitacion_publica,
        subasta_inversa,
        procesos
      } = params

      this.itemsConteo.push(
        {  id: 1, title: 'Compra en linea',       value: compra_linea,          color: '#404a7a' },
        {  id: 2, title: 'Contratación directa',  value: contratacion_directa,  color: '#404a7a' },
        {  id: 3, title: 'Convenio marco',        value: convenio_marco,        color: '#404a7a' },
        {  id: 4, title: 'Comparación de precios',         value: libre_gestion,         color: '#404a7a' },
        {  id: 5, title: 'Licitación competitiva',    value: licitacion_publica,    color: '#404a7a' },
        {  id: 6, title: 'Subasta inversa',       value: subasta_inversa,       color: '#404a7a' },
      )

      procesos.forEach( item => {
        if( item.fecha_inicio ) item.fecha_inicio = this.moment(item.fecha_inicio).format('DD/MM/YYYY')
        if( item.fecha_fin ) item.fecha_fin = this.moment(item.fecha_fin).format('DD/MM/YYYY')
        item.monto = this.filtros.contrato === 0  ? item.monto_presupuestado : item.monto_adjudicado
        item.monto = this.formatoDivisa(item.monto)
      })

      this.datosTabla = procesos
    },

    setDatosContrato (params) {
      const { total_contratos, total_ordenes_compra, contratos } = params
      this.itemsConteo.push(
        {  id: 1, title: 'Contratos',         value: total_contratos,       color: '#404a7a' },
        {  id: 2, title: 'Ordenes de compra', value: total_ordenes_compra,  color: '#404a7a' },
      )

      contratos.forEach( item => {
        if( item.fecha_inicio ) item.fecha_inicio = this.moment(item.fecha_inicio).format('DD/MM/YYYY')
        if( item.fecha_fin ) item.fecha_fin = this.moment(item.fecha_fin).format('DD/MM/YYYY')
        item.monto = this.filtros.contrato === 0  ? item.monto_presupuestado : item.monto_adjudicado
        item.monto = this.formatoDivisa(item.monto)
        item.estado = item.avance
      })

      this.datosTabla = contratos
    },

    async getTipoContratos () {
      const response  = await this.services.Reporteria.getReporteTipoContratos({ contrato : this.filtros.contrato })
      this.tipoContratos = response?.data
    },

    async getEstados () {
      const response = await this.services.Reporteria.getReporteEstado({ contrato : this.filtros.contrato })
      this.estados = response?.data
    },

    async getReportes (resetPaginacion = true) {
      if (this.filtros.contrato === null) return
      if(resetPaginacion) {
        this.filtros.page = 1,
        this.filtros.per_page = 10,
        this.filtros.total_rows = null
      }
      this.itemsFondos = []
      this.itemsConteo = []
      this.procesos = []
      const response = await this.services.Reporteria.getReportes(this.filtros)
      this.mostrarTabla = true

      if ( response?.status == 200) {
        const monto = this.filtros.contrato === 0 
          ? this.formatoDivisa(response?.data?.total_presupuesto)
          : this.formatoDivisa(response?.data?.total_adjudicado)
        this.itemsFondos.push({ 
          id: 1, 
          value: monto, 
          title: "Total presupuestario", 
          color: "yellow lighten-1" 
        })

        this.filtros.contrato === 0
          ? this.setDatosProcesoCompra(response?.data) 
          : this.setDatosContrato(response?.data)

        this.setPaginacion(response?.headers)
      }
    },

     setPaginacion (payload) {
      this.filtros.page = Number(payload.page)
      this.filtros.per_page = Number(payload.per_page)
      this.filtros.total_rows = Number(payload.total_rows)
    },

    async paginate(params) {
      const { cantidad_por_pagina, pagina} = params;
      this.filtros.page = pagina,
      this.filtros.per_page = cantidad_por_pagina
      await this.getReportes(false)
    },

    clean () {
      this.filtros = {
        contrato: null,
        id_tipo: null,
        id_estado: null,
        fecha_inicio: null,
        fecha_fin: null,
        page: 1,
        per_page: 10,
        total_rows: null,
      },
      this.formatFechaDesde = null
      this.formatFechaHasta = null
      this.tipoContratos = []
      this.estados = []
      this.mostrarTabla = false
      this.datosTabla = []
    }
  },

  async created () {}
};
</script>

<style scoped></style>
